var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c(
            "b-loading",
            {
              staticClass: "is-flex-direction-column",
              attrs: {
                "is-full-page": true,
                active: _vm.isLoading,
                "can-cancel": false,
              },
              on: {
                "update:active": function ($event) {
                  _vm.isLoading = $event
                },
              },
            },
            [
              _vm.isFileDownloading
                ? [
                    _c("p", { staticClass: "pb-3 px-3" }, [
                      _vm._v(
                        _vm._s(_vm.$t("component.report.file_download_info"))
                      ),
                    ]),
                    _c("b-icon", {
                      attrs: {
                        pack: "fas",
                        icon: "spinner",
                        size: "is-large",
                        "custom-class": "fa-pulse",
                      },
                    }),
                  ]
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _c("div", [
        _c(
          "div",
          { staticClass: "multi-header has-bottom-margin mx-3 mx-0-desktop" },
          [
            _c(
              "div",
              { staticClass: "is-flex-grow-1 is-flex" },
              [
                _c(
                  "h1",
                  {
                    staticClass: "title is-3 is-spaced has-text-centered-touch",
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("component.report.title")) + " ")]
                ),
                _vm.isReportSettingsAllowed
                  ? _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "configuration_reports",
                            params: {
                              lang: _vm.$route.params.lang,
                              departmentId: _vm.$route.params.departmentId,
                            },
                          },
                        },
                      },
                      [
                        _c(
                          "b-tooltip",
                          {
                            attrs: {
                              label: _vm.$t("component.report.report_config"),
                              position: "is-right",
                              type: "is-dark",
                            },
                          },
                          [
                            _c("b-icon", {
                              staticClass: "ml-2 mt-2",
                              staticStyle: { "font-size": "16px" },
                              attrs: { icon: "cog", type: "is-primary" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "is-flex-grow-2" },
              [
                !_vm.isLoading
                  ? _c("ReportConfigurationComponent", {
                      staticClass: "mb-5",
                      attrs: {
                        configuration: _vm.configuration,
                        reportExportSetting: _vm.reportExportSetting,
                        sourceGroups: _vm.sourceGroups,
                        sources: _vm.sources,
                        sourceGroupSources: _vm.sourceGroupSources,
                        selectedSourceGroupId: _vm.selectedSourceGroupId,
                        eventTypes: _vm.eventTypes,
                        dateTimeManager: _vm.dateTimeManager,
                        selectedDateRange: _vm.dateRange,
                        showEmpty: _vm.showEmpty,
                      },
                      on: { applyConfiguration: _vm.applyConfiguration },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
        !_vm.hasSources
          ? _c(
              "div",
              { staticClass: "tile is-parent" },
              [
                _c(
                  "b-message",
                  {
                    staticClass: "tile is-child",
                    attrs: { type: "is-warning", "has-icon": "" },
                  },
                  [
                    _vm._v(_vm._s(_vm.$t("error_messages.no_sources")) + " "),
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "configuration_loggers",
                            params: {
                              lang: _vm.$route.params.lang,
                              departmentId: _vm.$route.params.departmentId,
                            },
                          },
                        },
                      },
                      [
                        _c("strong", [
                          _vm._v(
                            _vm._s(_vm.$t("error_messages.no_sources_link"))
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.hasSources && !_vm.isLoading
          ? _c("div", { staticClass: "tile is-ancestor" }, [
              _c("div", { staticClass: "tile is-vertical" }, [
                _vm.$ability.can(
                  _vm.$permActions.READ,
                  _vm.$permSubjects.SOURCE_GROUP
                ) &&
                _vm.$ability.can(
                  _vm.$permActions.READ,
                  _vm.$permSubjects.SOURCE
                )
                  ? _c(
                      "div",
                      { staticClass: "tile is-parent" },
                      [
                        _c("SourceGroupPicker", {
                          staticClass: "tile is-child box",
                          attrs: {
                            sourceGroups: _vm.sourceGroups,
                            selectedSourceGroupId: _vm.selectedSourceGroupId,
                            sources: _vm.sources,
                            favoriteGroupId: _vm.favoriteGroupId,
                          },
                          on: {
                            sourceGroupSelected: _vm.sourceGroupSelected,
                            favoriteSelected: _vm.favoriteSelected,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.$ability.can(
                  _vm.$permActions.READ,
                  _vm.$permSubjects.SOURCE
                ) &&
                _vm.$ability.can(
                  _vm.$permActions.READ,
                  _vm.$permSubjects.REPORTS
                )
                  ? _c(
                      "div",
                      { staticClass: "tile is-parent" },
                      [
                        _c("ReportsOverallChart", {
                          staticClass: "tile is-child",
                          attrs: {
                            reportRecords: _vm.reportRecordsForChart,
                            eventTypes: _vm.eventTypes,
                            sourceGroupSources: _vm.sourceGroupSources,
                            dateFilter: _vm.monthFilter,
                            chartHeight: _vm.chartHeight,
                          },
                          on: {
                            axisRangeSelected: _vm.axisRangeSelected,
                            downloadCSV: _vm.openCsvModal,
                            downloadPDF: _vm.openPdfModal,
                            changeUserSetting: _vm.changeUserSetting,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.axisRangesLoaded == true &&
                _vm.$ability.can(
                  _vm.$permActions.READ,
                  _vm.$permSubjects.SOURCE
                ) &&
                _vm.$ability.can(
                  _vm.$permActions.READ,
                  _vm.$permSubjects.REPORTS
                )
                  ? _c(
                      "div",
                      { staticClass: "tile is-parent box-tabs" },
                      [
                        _c("SourceTabs", {
                          staticClass: "tile is-child box",
                          attrs: {
                            sourceGroupSources: _vm.sourceGroupSources,
                            sourceGroupStatistics: _vm.sourceGroupStatistics,
                            reportRecords: _vm.reportRecordsForTable,
                            allReportRecords: _vm.reportRecordsForChart,
                            eventTypes: _vm.eventTypes,
                            dateFilter: _vm.monthFilter,
                            axisRanges: _vm.axisRanges,
                            dateTimeManager: _vm.dateTimeManager,
                            filterRecords: _vm.filterRecords,
                            annotationsForSource: _vm.annotationsForSource,
                          },
                          on: {
                            reloadRecords: function ($event) {
                              return _vm.reloadRecords()
                            },
                            tabChanged: _vm.sourceSelected,
                            changeFilterRecords: _vm.changeFilterRecords,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.$featuresManager.isAvailable(_vm.$features.REPORTS_PDF) &&
                _vm.axisRangesLoaded == true &&
                _vm.$ability.can(
                  _vm.$permActions.READ,
                  _vm.$permSubjects.SOURCE
                ) &&
                _vm.$ability.can(
                  _vm.$permActions.READ,
                  _vm.$permSubjects.REPORTS
                )
                  ? _c(
                      "div",
                      {
                        staticClass: "tile is-parent",
                        attrs: { id: "ReportsList" },
                      },
                      [
                        _c("ReportsList", {
                          staticClass: "tile is-child box",
                          attrs: {
                            reports: _vm.reports,
                            dateTimeManager: _vm.dateTimeManager,
                            sources: _vm.sources,
                          },
                          on: {
                            downloadReport: _vm.downloadReport,
                            regenerateReport: _vm.regenerateReport,
                            removeReport: _vm.removeReport,
                            removeReports: _vm.removeReports,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ])
          : _vm._e(),
      ]),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isPdfModalActive,
            "has-modal-card": "",
            onCancel: _vm.closePdfModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isPdfModalActive = $event
            },
            "close-modal": _vm.closePdfModal,
          },
        },
        [
          _c("ExportPdfModal", {
            attrs: {
              refDateRange: _vm.dateRange,
              dateTimeManager: _vm.dateTimeManager,
              sourceGroups: _vm.sourceGroups,
              showEmpty: _vm.showEmpty,
              sources: _vm.sources,
              sourceGroupSources: _vm.sourceGroupSources,
              selectedSourceGroupId: _vm.selectedSourceGroupId,
              configuration: _vm.configuration,
              selectedMonth: new Date(),
              eventTypes: _vm.eventTypes,
              storedReportExportSetting: _vm.reportExportSetting,
            },
            on: {
              modalClosed: _vm.closePdfModal,
              sendingRequest: _vm.storeExportSetting,
            },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isCsvModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeCsvModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isCsvModalActive = $event
            },
            "close-modal": _vm.closeCsvModal,
          },
        },
        [
          _c("ExportCsvModal", {
            attrs: {
              dateTimeManager: _vm.dateTimeManager,
              sourceGroups: _vm.sourceGroups,
              showEmpty: _vm.showEmpty,
              sources: _vm.sources,
              sourceGroupSources: _vm.sourceGroupSources,
              selectedSourceGroupId: _vm.selectedSourceGroupId,
              configuration: _vm.configuration,
              selectedMonth: new Date(),
              eventTypes: _vm.eventTypes,
            },
            on: { modalClosed: _vm.closeCsvModal },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }