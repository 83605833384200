var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "aside",
    {
      staticClass: "is-narrow-mobile is-fullheight is-left-mobile side-menu",
      class: [
        _vm.isExpanded ? "column expanded-side-menu" : "bar-side-menu",
        _vm.isSideMenuVisible ? "checked" : null,
      ],
      attrs: { id: "side-menu" },
    },
    [
      _c("ul", { staticClass: "menu-list" }, [
        _c(
          "li",
          { staticClass: "main-category" },
          [
            _c(
              "router-link",
              {
                class: {
                  "is-active": _vm.isActive(["map"]),
                },
                attrs: {
                  to: {
                    name: "map",
                    params: {
                      lang: _vm.$route.params.lang,
                      departmentId: _vm.$route.params.departmentId,
                    },
                  },
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.onNavigation.apply(null, arguments)
                  },
                },
              },
              [
                _c("b-icon", { attrs: { icon: "map" } }),
                _vm.isExpanded || _vm.isSideMenuVisible
                  ? _c("span", { staticClass: "name" }, [
                      _vm._v(_vm._s(_vm.$t("navbar.map"))),
                    ])
                  : _vm._e(),
                _vm.mainCategories.MAP.expandable && _vm.isExpanded
                  ? _c(
                      "span",
                      [
                        _vm.mainCategories.MAP.expanded
                          ? _c("b-icon", {
                              attrs: { icon: "chevron-down" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.expandCategory(
                                    $event,
                                    _vm.mainCategories.MAP
                                  )
                                },
                              },
                            })
                          : _c("b-icon", {
                              attrs: { icon: "chevron-right" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.expandCategory(
                                    $event,
                                    _vm.mainCategories.MAP
                                  )
                                },
                              },
                            }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "li",
          { staticClass: "main-category" },
          [
            _c(
              "router-link",
              {
                class: {
                  "is-active": _vm.isActive(["dashboard"]),
                },
                attrs: {
                  to: {
                    name: "dashboard",
                    params: {
                      lang: _vm.$route.params.lang,
                      departmentId: _vm.$route.params.departmentId,
                    },
                  },
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.onNavigation.apply(null, arguments)
                  },
                },
              },
              [
                _c("b-icon", { attrs: { icon: "tachometer-alt" } }),
                _vm.isExpanded || _vm.isSideMenuVisible
                  ? _c("span", { staticClass: "name" }, [
                      _vm._v(_vm._s(_vm.$t("navbar.dashboard"))),
                    ])
                  : _vm._e(),
                _vm.mainCategories.DASHBOARD.expandable && _vm.isExpanded
                  ? _c(
                      "span",
                      [
                        _vm.mainCategories.DASHBOARD.expanded
                          ? _c("b-icon", {
                              attrs: { icon: "chevron-down" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.expandCategory(
                                    $event,
                                    _vm.mainCategories.DASHBOARD
                                  )
                                },
                              },
                            })
                          : _c("b-icon", {
                              attrs: { icon: "chevron-right" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.expandCategory(
                                    $event,
                                    _vm.mainCategories.DASHBOARD
                                  )
                                },
                              },
                            }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "li",
          { staticClass: "main-category" },
          [
            _c(
              "router-link",
              {
                class: {
                  "is-active": _vm.isActive(["groupDashboard"]),
                },
                attrs: {
                  to: {
                    name: "groupDashboard",
                    params: {
                      lang: _vm.$route.params.lang,
                      departmentId: _vm.$route.params.departmentId,
                    },
                  },
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.onNavigation.apply(null, arguments)
                  },
                },
              },
              [
                _c("b-icon", { attrs: { icon: "chart-line" } }),
                _vm.isExpanded || _vm.isSideMenuVisible
                  ? _c("span", { staticClass: "name" }, [
                      _vm._v(_vm._s(_vm.$t("navbar.group_dashboard"))),
                    ])
                  : _vm._e(),
                _vm.mainCategories.GROUP_DASHBOARD.expandable && _vm.isExpanded
                  ? _c(
                      "span",
                      [
                        _vm.mainCategories.GROUP_DASHBOARD.expanded
                          ? _c("b-icon", {
                              attrs: { icon: "chevron-down" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.expandCategory(
                                    $event,
                                    _vm.mainCategories.GROUP_DASHBOARD
                                  )
                                },
                              },
                            })
                          : _c("b-icon", {
                              attrs: { icon: "chevron-right" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.expandCategory(
                                    $event,
                                    _vm.mainCategories.GROUP_DASHBOARD
                                  )
                                },
                              },
                            }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
        _vm.$ability.can(_vm.$permActions.READ, _vm.$permSubjects.REPORTS)
          ? _c(
              "li",
              { staticClass: "main-category" },
              [
                _c(
                  "router-link",
                  {
                    class: {
                      "is-active": _vm.isActive(["reports"]),
                    },
                    attrs: {
                      to: {
                        name: "reports",
                        params: {
                          lang: _vm.$route.params.lang,
                          departmentId: _vm.$route.params.departmentId,
                        },
                      },
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.onNavigation.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("b-icon", { attrs: { icon: "paste" } }),
                    _vm.isExpanded || _vm.isSideMenuVisible
                      ? _c("span", { staticClass: "name" }, [
                          _vm._v(_vm._s(_vm.$t("navbar.reporting"))),
                        ])
                      : _vm._e(),
                    _vm.mainCategories.REPORTS.expandable && _vm.isExpanded
                      ? _c(
                          "span",
                          [
                            _vm.mainCategories.REPORTS.expanded
                              ? _c("b-icon", {
                                  attrs: { icon: "chevron-down" },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.expandCategory(
                                        $event,
                                        _vm.mainCategories.REPORTS
                                      )
                                    },
                                  },
                                })
                              : _c("b-icon", {
                                  attrs: { icon: "chevron-right" },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.expandCategory(
                                        $event,
                                        _vm.mainCategories.REPORTS
                                      )
                                    },
                                  },
                                }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _c(
          "li",
          { staticClass: "main-category" },
          [
            _c(
              "router-link",
              {
                class: {
                  "is-active": _vm.isActive(["notifications", "issueDetail"]),
                },
                attrs: {
                  to: {
                    name: "notifications",
                    params: {
                      lang: _vm.$route.params.lang,
                      departmentId: _vm.$route.params.departmentId,
                    },
                  },
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.onNavigation.apply(null, arguments)
                  },
                },
              },
              [
                _c("b-icon", { attrs: { icon: "bell" } }),
                _vm.isExpanded || _vm.isSideMenuVisible
                  ? _c("span", { staticClass: "name" }, [
                      _vm._v(_vm._s(_vm.$t("navbar.notification_board"))),
                    ])
                  : _vm._e(),
                _vm.mainCategories.NOTIFICATIONS.expandable && _vm.isExpanded
                  ? _c(
                      "span",
                      [
                        _vm.mainCategories.NOTIFICATIONS.expanded
                          ? _c("b-icon", {
                              attrs: { icon: "chevron-down" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.expandCategory(
                                    $event,
                                    _vm.mainCategories.NOTIFICATIONS
                                  )
                                },
                              },
                            })
                          : _c("b-icon", {
                              attrs: { icon: "chevron-right" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.expandCategory(
                                    $event,
                                    _vm.mainCategories.NOTIFICATIONS
                                  )
                                },
                              },
                            }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "li",
          { staticClass: "main-category" },
          [
            _c(
              "router-link",
              {
                class: {
                  "is-active": _vm.isActive(["settings"]),
                },
                attrs: {
                  to: {
                    name: "settings",
                    params: {
                      lang: _vm.$route.params.lang,
                      departmentId: _vm.$route.params.departmentId,
                    },
                  },
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.onNavigation.apply(null, arguments)
                  },
                },
              },
              [
                _c("b-icon", { attrs: { icon: "cog" } }),
                _vm.isExpanded || _vm.isSideMenuVisible
                  ? _c("span", { staticClass: "name" }, [
                      _vm._v(_vm._s(_vm.$t("component.settings.title"))),
                    ])
                  : _vm._e(),
                _vm.mainCategories.SETTINGS.expandable && _vm.isExpanded
                  ? _c(
                      "span",
                      [
                        _vm.mainCategories.SETTINGS.expanded
                          ? _c("b-icon", {
                              attrs: { icon: "chevron-down" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.expandCategory(
                                    $event,
                                    _vm.mainCategories.SETTINGS
                                  )
                                },
                              },
                            })
                          : _c("b-icon", {
                              attrs: { icon: "chevron-right" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.expandCategory(
                                    $event,
                                    _vm.mainCategories.SETTINGS
                                  )
                                },
                              },
                            }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
        _vm.$ability.can(_vm.$permActions.READ, _vm.$permSubjects.LOGGER) &&
        _vm.mainCategories.SETTINGS.expanded
          ? _c(
              "li",
              { staticClass: "sub-category" },
              [
                _c(
                  "router-link",
                  {
                    class: {
                      "is-active": _vm.isActive(
                        _vm.SettingOptions.LOGGERS.activeOnPages
                      ),
                    },
                    attrs: {
                      to: {
                        name: "configuration_loggers",
                        params: {
                          lang: _vm.$route.params.lang,
                          departmentId: _vm.$route.params.departmentId,
                        },
                      },
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.onNavigation.apply(null, arguments)
                      },
                    },
                  },
                  [
                    !_vm.isExpanded && !_vm.isSideMenuVisible
                      ? _c("b-icon", {
                          attrs: { icon: _vm.SettingOptions.LOGGERS.icon },
                        })
                      : _vm._e(),
                    _vm.isExpanded || _vm.isSideMenuVisible
                      ? _c("span", { staticClass: "name" }, [
                          _vm._v(_vm._s(_vm.$t("navbar.loggers"))),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.$ability.can(_vm.$permActions.READ, _vm.$permSubjects.SOURCE) &&
        _vm.$ability.can(
          _vm.$permActions.READ,
          _vm.$permSubjects.SOURCE_GROUP
        ) &&
        _vm.mainCategories.SETTINGS.expanded
          ? _c(
              "li",
              { staticClass: "sub-category" },
              [
                _c(
                  "router-link",
                  {
                    class: {
                      "is-active": _vm.isActive(
                        _vm.SettingOptions.GROUPS.activeOnPages
                      ),
                    },
                    attrs: {
                      to: {
                        name: "configuration_groups",
                        params: {
                          lang: _vm.$route.params.lang,
                          departmentId: _vm.$route.params.departmentId,
                        },
                      },
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.onNavigation.apply(null, arguments)
                      },
                    },
                  },
                  [
                    !_vm.isExpanded && !_vm.isSideMenuVisible
                      ? _c("b-icon", {
                          attrs: { icon: _vm.SettingOptions.GROUPS.icon },
                        })
                      : _vm._e(),
                    _vm.isExpanded || _vm.isSideMenuVisible
                      ? _c("span", { staticClass: "name" }, [
                          _vm._v(_vm._s(_vm.$t("navbar.groups"))),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.isReportSettingsAllowed && _vm.mainCategories.SETTINGS.expanded
          ? _c(
              "li",
              { staticClass: "sub-category" },
              [
                _c(
                  "router-link",
                  {
                    class: {
                      "is-active": _vm.isActive(
                        _vm.SettingOptions.REPORTS.activeOnPages
                      ),
                    },
                    attrs: {
                      to: {
                        name: "configuration_reports",
                        params: {
                          lang: _vm.$route.params.lang,
                          departmentId: _vm.$route.params.departmentId,
                        },
                      },
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.onNavigation.apply(null, arguments)
                      },
                    },
                  },
                  [
                    !_vm.isExpanded && !_vm.isSideMenuVisible
                      ? _c("b-icon", {
                          attrs: { icon: _vm.SettingOptions.REPORTS.icon },
                        })
                      : _vm._e(),
                    _vm.isExpanded || _vm.isSideMenuVisible
                      ? _c("span", { staticClass: "name" }, [
                          _vm._v(_vm._s(_vm.$t("navbar.reports"))),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.mainCategories.SETTINGS.expanded
          ? _c(
              "li",
              { staticClass: "sub-category" },
              [
                _c(
                  "router-link",
                  {
                    class: {
                      "is-active": _vm.isActive(
                        _vm.SettingOptions.NOTIFICATIONS.activeOnPages
                      ),
                    },
                    attrs: {
                      to: {
                        name: "configuration_notifications",
                        params: {
                          lang: _vm.$route.params.lang,
                          departmentId: _vm.$route.params.departmentId,
                        },
                      },
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.onNavigation.apply(null, arguments)
                      },
                    },
                  },
                  [
                    !_vm.isExpanded && !_vm.isSideMenuVisible
                      ? _c("b-icon", {
                          attrs: {
                            icon: _vm.SettingOptions.NOTIFICATIONS.icon,
                          },
                        })
                      : _vm._e(),
                    _vm.isExpanded || _vm.isSideMenuVisible
                      ? _c("span", { staticClass: "name" }, [
                          _vm._v(_vm._s(_vm.$t("navbar.notifications"))),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.currentConfig.features.public_dashboard === true &&
        _vm.$ability.can(
          _vm.$permActions.MANAGE,
          _vm.$permSubjects.DASHBOARD
        ) &&
        _vm.mainCategories.SETTINGS.expanded
          ? _c(
              "li",
              { staticClass: "sub-category" },
              [
                _c(
                  "router-link",
                  {
                    class: {
                      "is-active": _vm.isActive(
                        _vm.SettingOptions.DASHBOARDS.activeOnPages
                      ),
                    },
                    attrs: {
                      to: {
                        name: "publicDashboards",
                        params: {
                          lang: _vm.$route.params.lang,
                          departmentId: _vm.$route.params.departmentId,
                        },
                      },
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.onNavigation.apply(null, arguments)
                      },
                    },
                  },
                  [
                    !_vm.isExpanded && !_vm.isSideMenuVisible
                      ? _c("b-icon", {
                          attrs: { icon: _vm.SettingOptions.DASHBOARDS.icon },
                        })
                      : _vm._e(),
                    _vm.isExpanded || _vm.isSideMenuVisible
                      ? _c("span", { staticClass: "name" }, [
                          _vm._v(_vm._s(_vm.$t("navbar.dashboards"))),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.$ability.can(_vm.$permActions.LIST, _vm.$permSubjects.USERS) &&
        _vm.mainCategories.SETTINGS.expanded
          ? _c(
              "li",
              { staticClass: "sub-category" },
              [
                _c(
                  "router-link",
                  {
                    class: {
                      "is-active": _vm.isActive(
                        _vm.SettingOptions.ADMINISTRATION.activeOnPages
                      ),
                    },
                    attrs: {
                      to: {
                        name: "companySettings",
                        params: {
                          lang: _vm.$route.params.lang,
                          departmentId: _vm.$route.params.departmentId,
                        },
                      },
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.onNavigation.apply(null, arguments)
                      },
                    },
                  },
                  [
                    !_vm.isExpanded && !_vm.isSideMenuVisible
                      ? _c("b-icon", {
                          attrs: {
                            icon: _vm.SettingOptions.ADMINISTRATION.icon,
                          },
                        })
                      : _vm._e(),
                    _vm.isExpanded || _vm.isSideMenuVisible
                      ? _c("span", { staticClass: "name" }, [
                          _vm._v(_vm._s(_vm.$t("navbar.administration"))),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.mainCategories.SETTINGS.expanded
          ? _c(
              "li",
              { staticClass: "sub-category" },
              [
                _c(
                  "router-link",
                  {
                    class: {
                      "is-active": _vm.isActive(
                        _vm.SettingOptions.PROFILE.activeOnPages
                      ),
                    },
                    attrs: {
                      to: {
                        name: "profile_settings",
                        params: {
                          lang: _vm.$route.params.lang,
                          departmentId: _vm.$route.params.departmentId,
                        },
                      },
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.onNavigation.apply(null, arguments)
                      },
                    },
                  },
                  [
                    !_vm.isExpanded && !_vm.isSideMenuVisible
                      ? _c("b-icon", {
                          attrs: { icon: _vm.SettingOptions.PROFILE.icon },
                        })
                      : _vm._e(),
                    _vm.isExpanded || _vm.isSideMenuVisible
                      ? _c("span", { staticClass: "name" }, [
                          _vm._v(_vm._s(_vm.$t("navbar.profile"))),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.currentConfig.features.feedback === true
          ? _c(
              "li",
              { staticClass: "main-category" },
              [
                _c(
                  "router-link",
                  {
                    class: {
                      "is-active": _vm.isActive(["feedbackSettings"]),
                    },
                    attrs: {
                      to: {
                        name: "feedbackSettings",
                        params: {
                          lang: _vm.$route.params.lang,
                          departmentId: _vm.$route.params.departmentId,
                        },
                      },
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.onNavigation.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("b-icon", {
                      attrs: { icon: _vm.SettingOptions.FEEDBACK.icon },
                    }),
                    _vm.isExpanded || _vm.isSideMenuVisible
                      ? _c("span", { staticClass: "name" }, [
                          _vm._v(_vm._s(_vm.$t("navbar.feedback"))),
                        ])
                      : _vm._e(),
                    _vm.mainCategories.FEEDBACK.expandable && _vm.isExpanded
                      ? _c(
                          "span",
                          [
                            _vm.mainCategories.FEEDBACK.expanded
                              ? _c("b-icon", {
                                  attrs: { icon: "chevron-down" },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.expandCategory(
                                        $event,
                                        _vm.mainCategories.FEEDBACK
                                      )
                                    },
                                  },
                                })
                              : _c("b-icon", {
                                  attrs: { icon: "chevron-right" },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.expandCategory(
                                        $event,
                                        _vm.mainCategories.FEEDBACK
                                      )
                                    },
                                  },
                                }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _c(
          "li",
          { staticClass: "main-category", on: { click: _vm.logoutAsync } },
          [
            _c(
              "a",
              { attrs: { href: "#" } },
              [
                _c("b-icon", {
                  attrs: { icon: _vm.SettingOptions.LOG_OUT.icon },
                }),
                _vm.isExpanded || _vm.isSideMenuVisible
                  ? _c("span", { staticClass: "name" }, [
                      _vm._v(_vm._s(_vm.$t("navbar.logout"))),
                    ])
                  : _vm._e(),
                _vm.mainCategories.LOG_OUT.expandable && _vm.isExpanded
                  ? _c(
                      "span",
                      [
                        _vm.mainCategories.LOG_OUT.expanded
                          ? _c("b-icon", {
                              attrs: { icon: "chevron-down" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.expandCategory(
                                    $event,
                                    _vm.mainCategories.LOG_OUT
                                  )
                                },
                              },
                            })
                          : _c("b-icon", {
                              attrs: { icon: "chevron-right" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.expandCategory(
                                    $event,
                                    _vm.mainCategories.LOG_OUT
                                  )
                                },
                              },
                            }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
      ]),
      _c("b-icon", {
        staticClass: "width-button is-hidden-mobile",
        attrs: {
          icon: _vm.isExpanded ? "angle-double-left" : "angle-double-right",
        },
        nativeOn: {
          click: function ($event) {
            return _vm.expandButtonClicked(!_vm.isExpanded)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }