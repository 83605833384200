var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { staticClass: "subtitle is-4 mb-0" }, [
        _vm._v(
          _vm._s(_vm.$t("component.notification.presets.user_presets_title"))
        ),
      ]),
      _c("p", { staticClass: "is-size-7 is-italic has-text-grey mb-5" }, [
        _vm._v(
          _vm._s(_vm.$t("component.notification.presets.user_presets_desc"))
        ),
      ]),
      _c("div", { staticClass: "columns" }, [
        _c("div", { staticClass: "column is-one-fifth" }, [
          _c("h2", { staticClass: "subtitle has-text-dark" }, [
            _vm._v(_vm._s(_vm.$t("notifications.configuration.preset")) + ":"),
          ]),
        ]),
        _c("div", { staticClass: "column" }, [
          _c(
            "div",
            {
              staticClass: "is-flex flex-orientation-row is-align-items-center",
            },
            [
              _c(
                "b-field",
                { staticClass: "mb-0 mr-1" },
                [
                  _c(
                    "b-select",
                    {
                      attrs: {
                        disabled: _vm.isEditing,
                        placeholder: _vm.$t(
                          "notifications.configuration.select_preset_placeholder"
                        ),
                      },
                      on: { input: _vm.onPresetSelected },
                      model: {
                        value: _vm.selectedPreset,
                        callback: function ($$v) {
                          _vm.selectedPreset = $$v
                        },
                        expression: "selectedPreset",
                      },
                    },
                    _vm._l(_vm.localPresets, function (preset) {
                      return _c(
                        "option",
                        { key: preset.id, domProps: { value: preset } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                preset.id
                                  ? preset.name
                                  : _vm.$t(
                                      "notifications.configuration.new_preset"
                                    )
                              ) +
                              " "
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
              _vm.$ability.can(
                _vm.$permActions.EDIT,
                _vm.$permSubjects.DEPARTMENT_PRESETS
              )
                ? _c("b-button", {
                    staticClass: "mr-1",
                    attrs: {
                      "icon-left": _vm.isEditing
                        ? "times-circle"
                        : "pencil-alt",
                    },
                    on: { click: _vm.editPreset },
                  })
                : _vm._e(),
              _vm.$ability.can(
                _vm.$permActions.EDIT,
                _vm.$permSubjects.DEPARTMENT_PRESETS
              ) && _vm.selectedPreset.id != null
                ? _c("b-button", {
                    attrs: { "icon-left": "trash" },
                    on: { click: _vm.confirmDeletePreset },
                  })
                : _vm._e(),
              _vm.isEditing
                ? _c(
                    "b-button",
                    {
                      staticClass: "level-item ml-1",
                      attrs: { type: "is-primary" },
                      on: {
                        click: function ($event) {
                          return _vm.save()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("generals.save")) + " ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _c("div", { staticClass: "preset" }, [
            _vm.isEditing
              ? _c("div", { staticClass: "columns" }, [
                  _c(
                    "div",
                    { staticClass: "column is-half-desktop" },
                    [
                      _c(
                        "b-field",
                        {
                          staticClass: "required mb-1-mobile",
                          attrs: {
                            label: _vm.$t(
                              "component.notification.presets.table.name"
                            ),
                            type: {
                              "is-danger": _vm.errors.has(
                                _vm.$t(
                                  "component.notification.presets.table.name"
                                )
                              ),
                            },
                            message: _vm.errors.first(
                              _vm.$t(
                                "component.notification.presets.table.name"
                              )
                            ),
                          },
                        },
                        [
                          _c("b-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            attrs: {
                              placeholder: _vm.$t(
                                "component.notification.presets.table.name"
                              ),
                              type: "text",
                              maxlength: "50",
                              name: _vm.$t(
                                "component.notification.presets.table.name"
                              ),
                            },
                            model: {
                              value: _vm.selectedPreset.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.selectedPreset, "name", $$v)
                              },
                              expression: "selectedPreset.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "pt-5" },
              [
                _c("MeasuredRuleTemplate", {
                  attrs: {
                    types: _vm.types,
                    rule: _vm.singleMeasuredRule,
                    editIsAllowed: _vm.isEditing,
                  },
                  on: { ruleChanged: _vm.onRuleChange },
                }),
                _c("hr"),
                _c("GeneralRuleTemplate", {
                  attrs: {
                    type: _vm.getType(
                      _vm.NotificationSubscriptionType.DEVICE_OFFLINE
                    ),
                    rule: _vm.getRule(
                      _vm.NotificationSubscriptionType.DEVICE_OFFLINE
                    ),
                    editIsAllowed: _vm.isEditing,
                  },
                  on: { ruleChanged: _vm.onRuleChange },
                }),
                _c("hr"),
                _c("GeneralRuleTemplate", {
                  attrs: {
                    type: _vm.getType(
                      _vm.NotificationSubscriptionType.SOURCE_OFFLINE
                    ),
                    rule: _vm.getRule(
                      _vm.NotificationSubscriptionType.SOURCE_OFFLINE
                    ),
                    editIsAllowed: _vm.isEditing,
                  },
                  on: { ruleChanged: _vm.onRuleChange },
                }),
                _c("hr"),
                _c("h2", { staticClass: "title has-text-dark" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("notifications.configuration.other_rules_title")
                    )
                  ),
                ]),
                _c("GeneralRuleTemplate", {
                  attrs: {
                    type: _vm.getType(
                      _vm.NotificationSubscriptionType.LOW_BATTERY
                    ),
                    rule: _vm.getRule(
                      _vm.NotificationSubscriptionType.LOW_BATTERY
                    ),
                    editIsAllowed: _vm.isEditing,
                  },
                  on: { ruleChanged: _vm.onRuleChange },
                }),
                _c("GeneralRuleTemplate", {
                  attrs: {
                    type: _vm.getType(
                      _vm.NotificationSubscriptionType.RECORDS_UPDATED
                    ),
                    rule: _vm.getRule(
                      _vm.NotificationSubscriptionType.RECORDS_UPDATED
                    ),
                    editIsAllowed: _vm.isEditing,
                    advancedAllowed: false,
                  },
                  on: { ruleChanged: _vm.onRuleChange },
                }),
                _c("GeneralRuleTemplate", {
                  attrs: {
                    type: _vm.getType(
                      _vm.NotificationSubscriptionType
                        .CUSTOMER_ENDING_CALIBRATION
                    ),
                    rule: _vm.getRule(
                      _vm.NotificationSubscriptionType
                        .CUSTOMER_ENDING_CALIBRATION
                    ),
                    editIsAllowed: _vm.isEditing,
                    advancedAllowed: false,
                  },
                  on: { ruleChanged: _vm.onRuleChange },
                }),
                _c("GeneralRuleTemplate", {
                  attrs: {
                    type: _vm.getType(
                      _vm.NotificationSubscriptionType.PDF_RECORDS_REGENERATED
                    ),
                    rule: _vm.getRule(
                      _vm.NotificationSubscriptionType.PDF_RECORDS_REGENERATED
                    ),
                    editIsAllowed: _vm.isEditing,
                    advancedAllowed: false,
                  },
                  on: { ruleChanged: _vm.onRuleChange },
                }),
                _c("GeneralRuleTemplate", {
                  attrs: {
                    type: _vm.getType(
                      _vm.NotificationSubscriptionType
                        .AUTOMATIC_REPORTS_GENERATED
                    ),
                    rule: _vm.getRule(
                      _vm.NotificationSubscriptionType
                        .AUTOMATIC_REPORTS_GENERATED
                    ),
                    editIsAllowed: _vm.isEditing,
                    advancedAllowed: false,
                  },
                  on: { ruleChanged: _vm.onRuleChange },
                }),
                _c("GeneralRuleTemplate", {
                  attrs: {
                    type: _vm.getType(
                      _vm.NotificationSubscriptionType.LOGGER_STATE
                    ),
                    rule: _vm.getRule(
                      _vm.NotificationSubscriptionType.LOGGER_STATE
                    ),
                    editIsAllowed: _vm.isEditing,
                    advancedAllowed: false,
                  },
                  on: { ruleChanged: _vm.onRuleChange },
                }),
              ],
              1
            ),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }