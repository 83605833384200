export class SmartCitySensor {
    constructor() {
        this.additionalProperties = { popupVisibleOnMap: false };
        this.data = {};
    }
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => SmartCitySensor.define(et));
        }
        else if (data != null) {
            return SmartCitySensor.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let sensor = new SmartCitySensor();
        sensor.id = data.Id;
        sensor.name = data.Name;
        sensor.location = { latitude: data.Location.Latitude, longitude: data.Location.Longitude };
        sensor.values = data.Values.reverse();
        return sensor;
    }
}
