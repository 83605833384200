var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.activeStatistics && _vm.activeStatistics.length > 0
    ? _c("div", [
        _c("div", { staticClass: "tile is-ancestor" }, [
          _c("div", { staticClass: "tile is-parent" }, [
            !_vm.onPublicPage
              ? _c(
                  "div",
                  { staticClass: "box tile is-child has-text-centered-mobile" },
                  [
                    _c("p", { staticClass: "is-4" }, [
                      _c("strong", [
                        _vm._v(
                          _vm._s(_vm.$t("component.report.detail_table.logger"))
                        ),
                      ]),
                      _c("span", { staticClass: "pl-3 text-wrap-anywhere" }, [
                        _vm._v(_vm._s(_vm.activeSource.name)),
                      ]),
                    ]),
                    _c("p", { staticClass: "is-4" }, [
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("component.report.detail_table.calibrated")
                          )
                        ),
                      ]),
                      _c(
                        "span",
                        { staticClass: "pl-3" },
                        [
                          _vm.getCalibrationStatus() == "yes"
                            ? _c(
                                "b-tag",
                                { attrs: { rounded: "", type: "is-success" } },
                                [_vm._v(_vm._s(_vm.$t("generals.yes")) + " ")]
                              )
                            : _vm.getCalibrationStatus() == "no"
                            ? _c(
                                "b-tag",
                                { attrs: { rounded: "", type: "is-danger" } },
                                [_vm._v(_vm._s(_vm.$t("generals.no")) + " ")]
                              )
                            : _c(
                                "b-tag",
                                { attrs: { rounded: "", type: "is-warning" } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("generals.partly")) + " "
                                  ),
                                ]
                              ),
                          _c("span", { staticClass: "pl-1" }, [
                            _vm._v(_vm._s(_vm.getCalibrationRange())),
                          ]),
                        ],
                        1
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
          ]),
        ]),
        _c("h1", { staticClass: "subtitle is-4 mb-2" }, [
          _vm._v(
            " " + _vm._s(_vm.$t("component.report.statistics_title")) + " "
          ),
        ]),
        _c("table", { staticClass: "statistics-table table" }, [
          _c("tr", [
            _c("th"),
            _vm.hasTemperature
              ? _c("td", { staticClass: "has-text-weight-semibold" }, [
                  _vm._v(" " + _vm._s(_vm.$t("services.temperature")) + " "),
                ])
              : _vm._e(),
            _vm.hasHumidity
              ? _c("td", { staticClass: "has-text-weight-semibold" }, [
                  _vm._v(" " + _vm._s(_vm.$t("services.humidity")) + " "),
                ])
              : _vm._e(),
            _vm.hasDewPoint
              ? _c("td", { staticClass: "has-text-weight-semibold" }, [
                  _vm._v(" " + _vm._s(_vm.$t("services.dewpoint")) + " "),
                ])
              : _vm._e(),
          ]),
          _c("tr", [
            _c("th", [
              _vm._v(_vm._s(_vm.$t("component.report.detail_table.status"))),
            ]),
            _vm.hasTemperature
              ? _c(
                  "td",
                  [
                    _c(
                      "b-tag",
                      {
                        staticClass: "status-item",
                        attrs: {
                          rounded: "",
                          type: _vm.statisticsForSource.temperature.status
                            .class,
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.statisticsForSource.temperature.status.name
                          ) + " "
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.hasHumidity
              ? _c(
                  "td",
                  [
                    _c(
                      "b-tag",
                      {
                        staticClass: "status-item",
                        attrs: {
                          rounded: "",
                          type: _vm.statisticsForSource.humidity.status.class,
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.statisticsForSource.humidity.status.name) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.hasDewPoint
              ? _c(
                  "td",
                  [
                    _c(
                      "b-tag",
                      {
                        staticClass: "status-item",
                        attrs: {
                          rounded: "",
                          type: _vm.statisticsForSource.dewpoint.status.class,
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.statisticsForSource.dewpoint.status.name) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _c(
            "tr",
            [
              _c("th", [
                _vm._v(
                  _vm._s(_vm.$t("component.report.detail_table.boundaries"))
                ),
              ]),
              _vm.hasTemperature
                ? [
                    _vm.statisticsForSource.temperature.belowBoundary != null
                      ? _c("td", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.DecimalConversion.toCommaString(
                                  _vm.statisticsForSource.temperature
                                    .belowBoundary
                                )
                              ) +
                              _vm._s(_vm.statisticsForSource.temperature.unit) +
                              "- " +
                              _vm._s(
                                _vm.DecimalConversion.toCommaString(
                                  _vm.statisticsForSource.temperature
                                    .overBoundary
                                )
                              ) +
                              _vm._s(_vm.statisticsForSource.temperature.unit) +
                              " "
                          ),
                        ])
                      : _c("td", [_vm._v(_vm._s(_vm.$t("generals.na")))]),
                  ]
                : _vm._e(),
              _vm.hasHumidity
                ? [
                    _vm.statisticsForSource.humidity.belowBoundary != null
                      ? _c("td", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.DecimalConversion.toCommaString(
                                  _vm.statisticsForSource.humidity.belowBoundary
                                )
                              ) +
                              _vm._s(_vm.statisticsForSource.humidity.unit) +
                              "- " +
                              _vm._s(
                                _vm.DecimalConversion.toCommaString(
                                  _vm.statisticsForSource.humidity.overBoundary
                                )
                              ) +
                              _vm._s(_vm.statisticsForSource.humidity.unit) +
                              " "
                          ),
                        ])
                      : _c("td", [_vm._v(_vm._s(_vm.$t("generals.na")))]),
                  ]
                : _vm._e(),
              _vm.hasDewPoint
                ? [
                    _vm.statisticsForSource.dewpoint.belowBoundary != null
                      ? _c("td", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.DecimalConversion.toCommaString(
                                  _vm.statisticsForSource.dewpoint.belowBoundary
                                )
                              ) +
                              _vm._s(_vm.statisticsForSource.dewpoint.unit) +
                              "- " +
                              _vm._s(
                                _vm.DecimalConversion.toCommaString(
                                  _vm.statisticsForSource.dewpoint.overBoundary
                                )
                              ) +
                              _vm._s(_vm.statisticsForSource.dewpoint.unit) +
                              " "
                          ),
                        ])
                      : _c("td", [_vm._v(_vm._s(_vm.$t("generals.na")))]),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _c("tr", [
            _c("th", [
              _vm._v(
                _vm._s(_vm.$t("component.report.detail_table.below_time"))
              ),
            ]),
            _vm.hasTemperature
              ? _c("td", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.DateTimeManager.timestampToDuration(
                          _vm.statisticsForSource.temperature.belowTime
                        )
                      ) +
                      " "
                  ),
                ])
              : _vm._e(),
            _vm.hasHumidity
              ? _c("td", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.DateTimeManager.timestampToDuration(
                          _vm.statisticsForSource.humidity.belowTime
                        )
                      ) +
                      " "
                  ),
                ])
              : _vm._e(),
            _vm.hasDewPoint
              ? _c("td", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.DateTimeManager.timestampToDuration(
                          _vm.statisticsForSource.dewpoint.belowTime
                        )
                      ) +
                      " "
                  ),
                ])
              : _vm._e(),
          ]),
          _c("tr", [
            _c("th", [
              _vm._v(_vm._s(_vm.$t("component.report.detail_table.over_time"))),
            ]),
            _vm.hasTemperature
              ? _c("td", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.DateTimeManager.timestampToDuration(
                          _vm.statisticsForSource.temperature.overTime
                        )
                      ) +
                      " "
                  ),
                ])
              : _vm._e(),
            _vm.hasHumidity
              ? _c("td", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.DateTimeManager.timestampToDuration(
                          _vm.statisticsForSource.humidity.overTime
                        )
                      ) +
                      " "
                  ),
                ])
              : _vm._e(),
            _vm.hasDewPoint
              ? _c("td", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.DateTimeManager.timestampToDuration(
                          _vm.statisticsForSource.dewpoint.overTime
                        )
                      ) +
                      " "
                  ),
                ])
              : _vm._e(),
          ]),
          _c("tr", [
            _c("th", [
              _vm._v(_vm._s(_vm.$t("component.report.detail_table.avg"))),
            ]),
            _vm.hasTemperature
              ? _c("td", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.DecimalConversion.toCommaString(
                          _vm.statisticsForSource.temperature.avg
                        )
                      ) +
                      _vm._s(_vm.statisticsForSource.temperature.unit) +
                      " "
                  ),
                ])
              : _vm._e(),
            _vm.hasHumidity
              ? _c("td", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.DecimalConversion.toCommaString(
                          _vm.statisticsForSource.humidity.avg
                        )
                      ) +
                      _vm._s(_vm.statisticsForSource.humidity.unit) +
                      " "
                  ),
                ])
              : _vm._e(),
            _vm.hasDewPoint
              ? _c("td", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.DecimalConversion.toCommaString(
                          _vm.statisticsForSource.dewpoint.avg
                        )
                      ) +
                      _vm._s(_vm.statisticsForSource.dewpoint.unit) +
                      " "
                  ),
                ])
              : _vm._e(),
          ]),
          _c("tr", [
            _c("th", [
              _vm._v(
                _vm._s(_vm.$t("component.report.detail_table.highest_value"))
              ),
            ]),
            _vm.hasTemperature
              ? _c("td", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.DecimalConversion.toCommaString(
                          _vm.statisticsForSource.temperature.max
                        )
                      ) +
                      _vm._s(_vm.statisticsForSource.temperature.unit) +
                      " "
                  ),
                ])
              : _vm._e(),
            _vm.hasHumidity
              ? _c("td", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.DecimalConversion.toCommaString(
                          _vm.statisticsForSource.humidity.max
                        )
                      ) +
                      _vm._s(_vm.statisticsForSource.humidity.unit) +
                      " "
                  ),
                ])
              : _vm._e(),
            _vm.hasDewPoint
              ? _c("td", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.DecimalConversion.toCommaString(
                          _vm.statisticsForSource.dewpoint.max
                        )
                      ) +
                      _vm._s(_vm.statisticsForSource.dewpoint.unit) +
                      " "
                  ),
                ])
              : _vm._e(),
          ]),
          _c("tr", [
            _c("th", [
              _vm._v(
                _vm._s(_vm.$t("component.report.detail_table.lowest_value"))
              ),
            ]),
            _vm.hasTemperature
              ? _c("td", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.DecimalConversion.toCommaString(
                          _vm.statisticsForSource.temperature.min
                        )
                      ) +
                      _vm._s(_vm.statisticsForSource.temperature.unit) +
                      " "
                  ),
                ])
              : _vm._e(),
            _vm.hasHumidity
              ? _c("td", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.DecimalConversion.toCommaString(
                          _vm.statisticsForSource.humidity.min
                        )
                      ) +
                      _vm._s(_vm.statisticsForSource.humidity.unit) +
                      " "
                  ),
                ])
              : _vm._e(),
            _vm.hasDewPoint
              ? _c("td", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.DecimalConversion.toCommaString(
                          _vm.statisticsForSource.dewpoint.min
                        )
                      ) +
                      _vm._s(_vm.statisticsForSource.dewpoint.unit) +
                      " "
                  ),
                ])
              : _vm._e(),
          ]),
          _c("tr", [
            _c("th", [
              _vm._v(_vm._s(_vm.$t("component.report.detail_table.mkt"))),
            ]),
            _vm.hasTemperature
              ? _c("td", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.DecimalConversion.toCommaString(
                          _vm.statisticsForSource.temperature.mkt
                        )
                      ) +
                      _vm._s(_vm.statisticsForSource.temperature.unit) +
                      " "
                  ),
                ])
              : _vm._e(),
            _vm.hasHumidity
              ? _c("td", [_vm._v(_vm._s(_vm.$t("generals.na")))])
              : _vm._e(),
            _vm.hasDewPoint
              ? _c("td", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.DecimalConversion.toCommaString(
                          _vm.statisticsForSource.dewpoint.mkt
                        )
                      ) +
                      _vm._s(_vm.statisticsForSource.dewpoint.unit) +
                      " "
                  ),
                ])
              : _vm._e(),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }