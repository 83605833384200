var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("section", [
      _c("div", { staticClass: "multi-header has-bottom-margin" }, [
        _c(
          "div",
          {
            staticClass:
              "title-with-edit is-flex is-align-content-center is-align-items-flex-start",
          },
          [
            _c(
              "h2",
              {
                staticClass:
                  "subtitle is-4 is-spaced has-text-centered-touch mr-2",
              },
              [_vm._v(" " + _vm._s(_vm.$t("tiers.active_tier")) + " ")]
            ),
            _vm.$ability.can(
              _vm.$permActions.CUSTOMIZE,
              _vm.$permSubjects.ADMIN_DEPARTMENTS_TIERS
            )
              ? _c("b-button", {
                  class: _vm.isEditing && "has-text-primary",
                  attrs: { "icon-right": "pencil-alt" },
                  on: {
                    click: function ($event) {
                      return _vm.editTier()
                    },
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      !_vm.activeTier
        ? _c("div", { staticClass: "has-text-centered" }, [
            _c(
              "span",
              { staticClass: "has-text-primary has-text-weight-bold" },
              [_vm._v(" " + _vm._s(_vm.$t("tiers.no_active_tier")))]
            ),
          ])
        : _c(
            "div",
            [
              !_vm.isEditing
                ? _c("CompanyTierDetail", {
                    attrs: {
                      activeTier: _vm.activeTier,
                      dateTimeManager: _vm.dateTimeManager,
                    },
                  })
                : _c("CompanyTierDetailForm", {
                    attrs: {
                      activeTier: _vm.activeTier,
                      dateTimeManager: _vm.dateTimeManager,
                    },
                    on: { updateTier: _vm.updateTier },
                  }),
              _c("hr"),
              _c("TierUsageTable", { attrs: { activeTier: _vm.activeTier } }),
            ],
            1
          ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }