import AxiosService from '@/services/api/AxiosService';
import ApiLinks from '@/entities/ApiLinks';
import ApiStatus from '@/entities/enums/apiStatuses';
import { vxm } from '@/store/store.vuex';
import { SmartCitySensor } from '@/entities/models/SmartCitySensor';
import { ApiQueries } from '@/entities/ApiQueries';
var vxDepartmentStore = vxm.departmentStore;
export default class SmartCitySensorRepository {
    constructor(ctx) {
        this.ctx = ctx;
    }
    async getSmartCitySensors(latStart, latEnd, longStart, longEnd, departmentId = this.getSelectedDepartmentId()) {
        let url = `${ApiLinks.SmartCitySensors.Home}/${departmentId}${ApiLinks.SmartCitySensors.Keys.Sensors}`;
        let queries = new ApiQueries();
        if (latStart)
            queries.latStart = latStart;
        if (latEnd)
            queries.latEnd = latEnd;
        if (longStart)
            queries.longStart = longStart;
        if (longEnd)
            queries.longEnd = longEnd;
        if (queries.hasQueries())
            url += `?${queries.toQueryString()}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return [];
        else
            return SmartCitySensor.fromApi(response.data.data);
    }
    async getSmartCitySensorData(sensorId, departmentId = this.getSelectedDepartmentId()) {
        let url = `${ApiLinks.SmartCitySensors.Home}/${departmentId}${ApiLinks.SmartCitySensors.Keys.Sensors}/${sensorId}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return {};
        else
            return response.data.data;
    }
    async getSmartCitySensorsPublic(latStart, latEnd, longStart, longEnd) {
        let url = `${ApiLinks.SmartCitySensors.Home}${ApiLinks.SmartCitySensors.Keys.Public}${ApiLinks.SmartCitySensors.Keys.Sensors}`;
        let queries = new ApiQueries();
        if (latStart)
            queries.latStart = latStart;
        if (latEnd)
            queries.latEnd = latEnd;
        if (longStart)
            queries.longStart = longStart;
        if (longEnd)
            queries.longEnd = longEnd;
        if (queries.hasQueries())
            url += `?${queries.toQueryString()}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return [];
        else
            return SmartCitySensor.fromApi(response.data.data);
    }
    async getSmartCitySensorDataPublic(sensorId) {
        let url = `${ApiLinks.SmartCitySensors.Home}${ApiLinks.SmartCitySensors.Keys.Public}${ApiLinks.SmartCitySensors.Keys.Sensors}/${sensorId}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return {};
        else
            return response.data.data;
    }
    getSelectedDepartmentId() {
        return vxDepartmentStore.selectedDepartment.id;
    }
}
